import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SupplierEffects } from './supplier-effects';
import { SupplierFacade } from './supplier-facade';

import { supplierReducer } from './supplier-reducer';

@NgModule({
  imports: [StoreModule.forFeature('supplier', supplierReducer), EffectsModule.forFeature([SupplierEffects])],
  providers: [SupplierEffects, SupplierFacade],
})
export class SupplierStoreModule {}
