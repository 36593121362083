import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ConfigActions from './config-actions';
import { ConfigDataService } from '@services/data-integration/config-data.service';

@Injectable()
export class ConfigEffects {
  constructor(
    private actions$: Actions,
    private configDataService: ConfigDataService,
  ) {}

  public getTimezonesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigActions.GetTimezonesAction),
      switchMap(() => {
        return this.configDataService.getTimezones().pipe(
          map((response) => ConfigActions.GetTimezonesSuccessAction(response)),
          catchError((err: HttpErrorResponse) => [ConfigActions.GetTimezonesFailAction({ error: err.error })]),
        );
      }),
    ),
  );
}
