import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigDataService {
  private readonly timezonesUrl = 'https://worldtimeapi.org/api/timezone';

  constructor(private http: HttpClient) {}

  public getTimezones(): Observable<string[]> {
    return this.http.get<string[]>(this.timezonesUrl);
  }
}
