import { createAction, props } from '@ngrx/store';

enum AuthActionsTypes {
  SET_USER_LOGGED = '[USER_AUTH] Set User Logged',
  LOGIN = '[USER_AUTH] Login',
  LOGIN_SUCCESS = '[USER_AUTH] Login Success',
  LOGIN_FAIL = '[USER_AUTH] Login Fail',
  LOGOUT = '[USER_AUTH] Logout',
}

export const SetUserLogged = createAction(AuthActionsTypes.SET_USER_LOGGED);

export const LoginAction = createAction(
  AuthActionsTypes.LOGIN,
  props<{ payload: { email: string; password: string } }>(),
);

export const LoginSuccessAction = createAction(AuthActionsTypes.LOGIN_SUCCESS);

export const LoginFailAction = createAction(AuthActionsTypes.LOGIN_FAIL, props<{ error: string }>());

export const LogoutAction = createAction(AuthActionsTypes.LOGOUT);
