import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserRoleTypes } from '@enums';
import { AlertService } from '@services/utils/alert.service';

import { AuthFacade, UserFacade } from '@store';
import { Subject } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private authFacade: AuthFacade,
    private alertService: AlertService,
    private userFacade: UserFacade,
    private translocoService: TranslocoService,
  ) {}

  private onDestroy = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public ngOnInit(): void {
    this.authFacade.isUserLogged$.pipe(filter(Boolean), takeUntil(this.onDestroy)).subscribe(() => {
      this.userFacade.getCurrentUser();

      const eligibleRoles = [UserRoleTypes.SUBCONTRACTOR, UserRoleTypes.SUPPLIER];

      this.userFacade.currentUser$.pipe(filter(Boolean), take(1)).subscribe((user) => {
        if (!eligibleRoles.includes(user?.role.type)) {
          this.alertService.alert(this.translocoService.translate('alert.application_available_with_permissions'));

          this.authFacade.logout();
        }
      });
    });
  }
}
