import { NgModule, inject } from '@angular/core';
import { provideApollo } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@env/environment';
import { AUTH_TOKEN_KEY } from '@constants';

const uri = `${environment.apiNgUrl}/graphql`;

export const createApollo = (httpLink: HttpLink): ApolloClientOptions<any> => {
  const auth = setContext((_, { headers }) => {
    const token = getToken();
    return token
      ? {
          headers: {
            Authorization: token,
            ...headers,
          },
        }
      : headers;
  });

  const link = ApolloLink.from([auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
};

const getToken = () => {
  const match = document.cookie.match(new RegExp('(^| )' + AUTH_TOKEN_KEY + '=([^;]+)'));
  return match ? match[2] : null;
};

@NgModule({
  providers: [
    provideApollo(() => {
      const httpLink = inject(HttpLink);
      return createApollo(httpLink);
    }),
  ],
})
export class GraphQLModule {}
