import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { configReducer } from './config-reducer';
import { ConfigEffects } from './config-effects';
import { ConfigFacade } from './config-facade';

@NgModule({
  imports: [StoreModule.forFeature('config', configReducer), EffectsModule.forFeature([ConfigEffects])],
  providers: [ConfigEffects, ConfigFacade],
})
export class ConfigStoreModule {}
