import { createAction, props } from '@ngrx/store';

import { User } from '@interfaces';

enum UserActionsTypes {
  GET_USER_REQUEST = '[USER] Get current user request',
  GET_USER_SUCCESS = '[USER] Get current user success',
  GET_USER_FAIL = '[USER] Get current user error',
}

export const GetUserRequestAction = createAction(UserActionsTypes.GET_USER_REQUEST);

export const GetUserSuccessAction = createAction(UserActionsTypes.GET_USER_SUCCESS, props<{ user: User }>());

export const GetUserFailAction = createAction(UserActionsTypes.GET_USER_FAIL, props<{ error: string }>());
