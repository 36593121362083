import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ConfigState } from '@interfaces';

import { selectTimezones, selectTimezonesError, selectTimezonesLoading } from './config-selectors';
import { GetTimezonesAction } from './config-actions';

@Injectable()
export class ConfigFacade {
  public timezones$ = this.store.select(selectTimezones);
  public timezonesLoading$ = this.store.select(selectTimezonesLoading);
  public timezonesError$ = this.store.select(selectTimezonesError);

  constructor(private store: Store<ConfigState>) {}

  public getTimezones(): void {
    this.store.dispatch(GetTimezonesAction());
  }
}
