import { SupplierState } from '@interfaces';
import { createReducer, on } from '@ngrx/store';

import * as SupplierActions from './supplier-actions';
import { supplierInitialState } from './supplier-state';

export const supplierReducer = createReducer(
  supplierInitialState,
  on(SupplierActions.GetSupplierLocationId, (state) => {
    return {
      ...state,
    };
  }),
  on(SupplierActions.GetSupplierLocationIdSuccess, (state, data: SupplierState) => {
    return {
      ...state,
      ...data,
    };
  }),
);
