import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslocoModule } from '@jsverse/transloco';

import { NgZorroModule } from '@shared/modules/ng-zorro.module';
import { DayPipe } from '@pipes/day.pipe';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { IterablePipe } from '@pipes/iterable.pipe';
import { TranslationPipe } from '@pipes/translation.pipe';
import { EmptyPipe } from '@pipes/empty.pipe';

const components = [SvgIconComponent];
const pipes = [DayPipe, IterablePipe, TranslationPipe, EmptyPipe];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    InlineSVGModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgZorroModule,
    TranslocoModule,
  ],
  exports: [FormsModule, ReactiveFormsModule, NgZorroModule, RouterModule, TranslocoModule, ...components, ...pipes],
})
export class SharedModule {}
