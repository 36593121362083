import { Injectable } from '@angular/core';
import { SupplierState } from '@interfaces';
import { Store } from '@ngrx/store';
import { GetSupplierLocationId } from './supplier-actions';
import { selectSupplierLocationId } from './supplier-selectors';

@Injectable()
export class SupplierFacade {
  public getSupplierLocationId$ = this.store.select(selectSupplierLocationId);

  constructor(private store: Store<SupplierState>) {}

  public getCurrentSupplierLocationId(userId: string): void {
    this.store.dispatch(GetSupplierLocationId({ userId }));
  }
}
